import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import clsx from 'clsx';

import { IonButton, IonButtons, IonHeader, IonToolbar, useIonRouter } from '@ionic/react';

import BrandGobazarLongIcon from '@/components/icons/brands/BrandGobazarLongIcon.tsx';
import ArrowLeftIcon from '@/components/icons/common/ArrowLeftIcon.tsx';
import { storageKeys } from '@/consts/storageKeys.ts';
import { ILocaleEnum } from '@/types/ILocaleEnum.ts';

export default function Header({
  backUrl,
  isFullWidth,
  isLogo,
  isWhite,
  label,
}: {
  backUrl?: string;
  isFullWidth?: boolean;
  isLogo?: boolean;
  isWhite?: boolean;
  label?: string;
}) {
  const ionRouter = useIonRouter();
  const {
    i18n: { changeLanguage, language },
  } = useTranslation();

  return (
    <IonHeader
      className={clsx(
        'lg:var-bg-white',
        isWhite && 'var-bg-white',
        isFullWidth ? 'w-full' : 'application-width mx-auto',
      )}
    >
      <IonToolbar
        className={clsx(
          'flex h-14 items-center overflow-hidden pr-2 lg:h-16 lg:px-8',
          backUrl ? 'pl-2' : 'pl-4',
        )}
      >
        <div className="flex flex-grow items-center gap-2 overflow-hidden" slot="start">
          {backUrl && (
            <Link
              aria-label="GoBazar"
              onClick={(e) => {
                e.preventDefault();
                ionRouter.push(backUrl, 'back');
              }}
              to={backUrl}
            >
              <ArrowLeftIcon />
            </Link>
          )}

          {isLogo && (
            <Link aria-label="GoBazar" to="/">
              <BrandGobazarLongIcon className="h-6 w-36 px-0 lg:h-8 lg:w-46" />
            </Link>
          )}

          {!!label && <h1 className="truncate text-18 font-5 text-[#040415]">{label}</h1>}
        </div>

        <IonButtons className="h-12" slot="primary">
          {Object.entries(ILocaleEnum).map(([locale, label]) => (
            <IonButton
              className={clsx(
                'm-0 h-6 !min-h-0 w-[36px] text-14 capitalize lg:h-8 lg:w-14 lg:text-18 lg:font-5',
              )}
              fill={locale === language ? 'solid' : 'default'}
              key={locale}
              onClick={() => {
                changeLanguage(locale).then(() => {
                  localStorage.setItem(storageKeys.locale, locale);
                });
              }}
              shape="round"
              size="small"
            >
              {label.substring(0, 3)}
            </IonButton>
          ))}
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
}
